<template>

    <div>
        <v-container id="dashboard" fluid tag="section">
            <v-text-field class="mt-4" label="اكتب للبحث" outlined append-icon="mdi-magnify" v-model="search">
            </v-text-field>

            <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1" :items-per-page="25">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title> {{ $t("Drawer.GalleryCats") }} </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs"
                                    v-on="on">
                                    {{ $t("add_new") }}
                                </v-btn>
                            </template>
                            <v-form ref="form" v-model="valid">
                                <v-card>

                                    <v-toolbar dark color="primary lighten-1 mb-5">
                                        <v-toolbar-title>
                                            <h3 style="color:#fff"> {{formTitle}}</h3>
                                        </v-toolbar-title>
                                        <v-spacer />
                                        <v-btn @click="close()" icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>

                                    <v-card-text>
                                        <v-container>

                                            <v-row>
                                                <v-col class="py-0" cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.title"
                                                        :label="$t('datatable.name')" outlined>
                                                    </v-text-field>
                                                </v-col>






                                            </v-row>

                                            <v-row row wrap>
                                                <v-col class="py-0" cols="12" sm="12" md="4">
                                                    <v-radio-group v-model="radioGroup">
                                                        <v-radio label="قسم رئسي" :value="1"></v-radio>

                                                        <v-radio label="قسم فرعي" :value="2"></v-radio>
                                                    </v-radio-group>


                                                </v-col>

                                            </v-row>

                                            <v-row row wrap v-if="radioGroup==2">
                                                <v-col class="py-0" cols="12" sm="12" md="4">
                                                    <v-select :items="maincat" v-model="editedItem.maincat_id"
                                                        item-text="title" item-value="id" label="اختر القسم الرئسي"
                                                        outlined></v-select>
                                                </v-col>
                                            </v-row>





                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1" text @click="close()">{{ $t("close") }}
                                        </v-btn>
                                        <v-btn :loading="loadSave" color="blue darken-1" @click="save()" text>
                                            {{ $t("save") }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">





                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-5" @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs"
                                v-on="on">mdi-pencil</v-icon>
                        </template>
                        <span>{{ $t("edite") }} </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="deleteItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                                mdi-delete</v-icon>
                        </template>
                        <span>{{$t('Delete')}}</span>
                    </v-tooltip>



                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
    import Axios from "axios";
    //   import Swal from "sweetalert2";
    export default {
        data() {
            return {
                desserts: [

                ],

                cats: [],
                dialog: false,
                radioGroup: 1,
                loadSave: false,
                imagesUrl: '',
                imageName: '',
                imageUrl: '',
                img_old: '',
                imageFile: '',

                imagesName: ' ',
                editedIndex: -1,
                editedItem: {
                    maincat_id: null,
                    id: "",
                    title: '',


                    // translations: [{
                    //         name: ''
                    //     },
                    //     {
                    //         name: ''
                    //     }
                    // ],
                    // id: "",
                    // title: "",

                    // description: ""

                },

                items: [

                ],
                maincat: [],
                headers: [

                    {
                        text: this.$t('datatable.name'),
                        align: "start",
                        value: "title"
                    },

                    {
                        text: 'القسم الرئسي',
                        align: "start",
                        value: "videoscat.title"
                    },

                    ///videoscat


                    {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ],
                right: null
            }
        },

        methods: {
         

            next() {
                this.$refs.slick.next();
            },

            prev() {
                this.$refs.slick.prev();
            },

            reInit() {
                // Helpful if you have to deal with v-for to update dynamic lists
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },

            // Events listeners
            handleAfterChange(event, slick, currentSlide) {
                console.log('handleAfterChange', event, slick, currentSlide);
            },
            handleBeforeChange(event, slick, currentSlide, nextSlide) {
                console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
            },
            handleBreakpoint(event, slick, breakpoint) {
                console.log('handleBreakpoint', event, slick, breakpoint);
            },
            handleDestroy(event, slick) {
                console.log('handleDestroy', event, slick);
            },
            handleEdge(event, slick, direction) {
                console.log('handleEdge', event, slick, direction);
            },
            handleInit(event, slick) {
                console.log('handleInit', event, slick);
            },
            handleReInit(event, slick) {
                console.log('handleReInit', event, slick);
            },
            handleSetPosition(event, slick) {
                console.log('handleSetPosition', event, slick);
            },
            handleSwipe(event, slick, direction) {
                console.log('handleSwipe', event, slick, direction);
            },
            handleLazyLoaded(event, slick, image, imageSource) {
                console.log('handleLazyLoaded', event, slick, image, imageSource);
            },
            handleLazeLoadError(event, slick, image, imageSource) {
                console.log('handleLazeLoadError', event, slick, image, imageSource);
            },



            editItem(item) {
                this.editedIndex = this.desserts.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;

            },
            close() {
                this.dialog = false;
                // this.editedItem ={};
                this.editedItem = {
                    translations: [{
                            title: '',
                            description: ''
                        },
                        {
                            title: '',
                            description: ''
                        }
                    ],
                    id: "",
                    title: "",

                    description: "",

                    photo_gallery_cats_id: ""

                };
                // this.$nextTick(() => {
                //     this.editedItem = Object.assign({}, this.defaultItem);
                //     this.editedIndex = -1;
                // });
            },

            getmaincat() {
                Axios.get("GalleryAlbums/getMainCat", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        // this.loading = false;
                        this.maincat = res.data.data;
                        //  console.log(res.data);
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            initialize() {
                this.loading = true;
                Axios.get("GalleryAlbums", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.desserts = res.data.data;
                        //  console.log(res.data);
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },



            deleteItem(item) {

                item
                // Swal.fire({
                //   title: this.$t('sure_process'),
                //   text: "",
                //   icon: "warning",
                //   showCancelButton: true,
                //   confirmButtonColor: "#3085d6",
                //   cancelButtonColor: "#d33",
                //   confirmButtonText:  this.$t('yes'),
                //   cancelButtonText: this.$t('no'),
                // }).then(result => {
                //   if (result.value) {
                //     Axios.delete("articles/" + item.id, {
                //         headers: {
                //           "Content-Type": "application/json",
                //           Accept: "application/json",
                //           Authorizations: "Bearer " +this.$store.state.AdminInfo.token
                //         }
                //       })
                //       .then(() => {
                //         this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");
                //         this.initialize();
                //       })
                //       .catch(() => {
                //         this.$swal.fire( this.$t('not_successful'), this.$t('not_done'), "error");
                //       });
                //   }
                // });
            },




            save() {
                this.loadSave = true;
                if (this.editedIndex > -1) {

                    this.axios
                        .patch("GalleryAlbums/" + this.editedItem.id, this.editedItem, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                            },
                        })
                        .then(() => {
                            this.loadSave = false;
                            this.initialize();
                            this.close();
                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })
                        .catch(() => {
                            this.loadSave = false;

                            this.$swal.fire({
                                title: "تاكد من ملى المعلومات",
                                text: "",
                                icon: "error",
                                confirmButtonText: "اغلاق",
                            });
                        });
                } else {

                    this.axios
                        .post("GalleryAlbums", this.editedItem, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                            }
                        })
                        .then(() => {
                            this.loadSave = false;
                            this.initialize();
                            this.editedIndex = -1;
                            this.close();
                            this.$swal.fire({
                                title: this.$t('Added'),
                                text: "",
                                icon: "success",
                                confirmButtonText: this.$t('close'),
                            });



                        })
                        .catch((err) => {
                            err

                            this.loadSave = false;

                        });
                }


            },

        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('add_new') : this.$t('update');

            },
        },
        created() {
            this.initialize();
            this.getmaincat();
      
        },

    }
</script>